import React from 'react';
import { useLocation } from 'react-router-dom';
import RestaurantMenuWrapper from '../Customer/menu'; // Adjust the import path as needed

const SessionWrapper = () => {
  const location = useLocation();
  const guestId = location.state?.guestId;

  return <RestaurantMenuWrapper guestId={guestId} />;
};

export default SessionWrapper; 
