import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../../Styles/billing.css';

const Billing = () => {
  const [restaurantId, setRestaurantId] = useState('');
  const [stripeID, setStripeID] = useState(null);
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setRestaurantId(user.uid);

        // Fetch stripeID from Firestore
        const stripeDocRef = doc(db, `restaurant/${user.uid}/branch/stripe`);
        const stripeDocSnap = await getDoc(stripeDocRef);

        if (stripeDocSnap.exists() && stripeDocSnap.data().stripeID) {
          setStripeID(stripeDocSnap.data().stripeID);
        }

        // Fetch email from Firestore
        const ownerDocRef = doc(db, `restaurant/${user.uid}/branch/owner`);
        const ownerDocSnap = await getDoc(ownerDocRef);
        if (ownerDocSnap.exists()) {
          setEmail(ownerDocSnap.data().email);
        }

        // Fetch country from Firestore
        const addressDocRef = doc(db, `restaurant/${user.uid}/branch/address`);
        const addressDocSnap = await getDoc(addressDocRef);
        if (addressDocSnap.exists()) {
          setCountry(addressDocSnap.data().country);
        }

        setIsLoading(false);
      } else {
        console.log('User is not logged in');
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  async function createStripeAccount() {
    if (!restaurantId || !email || !country) {
      console.error('Required information is missing');
      return;
    }

    setIsButtonLoading(true);
  
    console.log('Sending request to create Stripe account with restaurantId:', restaurantId);
  
    try {
      const response = await fetch('https://us-central1-nowaitr-70bf2.cloudfunctions.net/api/create-stripe-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ restaurantId, email, country }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);
        throw new Error(`Error creating Stripe account: ${errorText}`);
      }
  
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Response data:', data);
  
        // Redirect the user to the Stripe onboarding URL
        window.location.href = data.accountLink;
      } else {
        console.error('Expected JSON response but got:', contentType);
        throw new Error('Expected JSON response');
      }
    } catch (error) {
      console.error('Error creating Stripe account:', error);
    } finally {
      setIsButtonLoading(false);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (stripeID) {
    // Content when stripeID exists
    return (
      <div className="billing-container">
        <div className="billing-content">
          <h1>Your Stripe Dashboard</h1>
          <p>Here you can manage your payments, view transaction history, and more.</p>
          <button className="stripe-setup-button" onClick={() => window.location.href = 'https://dashboard.stripe.com/login'}>Go to Dashboard</button>
        </div>
      </div>
    );
  }

  // Content when stripeID does not exist
  return (
    <div className="billing-container">
      <div className="billing-content">
        <h1>Create your Stripe-Account</h1>
        <p className="billing-description">
          To get started accepting payments, register with our trusted global partner, <strong>Stripe</strong>.
          Stripe offers secure, reliable payment processing for businesses of all sizes.
        </p>
        <button className="stripe-setup-button" onClick={createStripeAccount} disabled={isButtonLoading}>
          {isButtonLoading ? (
            <div className="spinner"></div>
          ) : (
            'Set Up Stripe Account'
          )}
        </button>
      </div>
    </div>
  );
};

export default Billing;