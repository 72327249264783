import React from 'react';
import { useLocation } from 'react-router-dom';
import StripePricingTable from '../Owner/StripePricingTable';

export default function Subscribe() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get('uid');

  // Log the uid to the console for debugging
  console.log('User UID:', uid);

  return (
    <div>
      <StripePricingTable clientReferenceId={uid} />
    </div>
  );
}
