import React, { useState, useEffect } from 'react';
import { firestore } from '../Firebase/firebaseConfig';
import { collection, doc, deleteDoc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import '../Styles/cart.css';
import { useParams, useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import TextField from '@mui/material/TextField';



/*const stripePromise = loadStripe('pk_test_51OOfyDKIP1dcMwGFxjFgOhkd1tjw7U6Wss1BFmz9bXfpqDlnB0OozV4ZyD106lAGJeGOvgSbVUH3uJbE9LP4rygc00PflvE62z', {
    stripeAccount: {stripeAccountId},
  }); */
  

const CartWithParams = () => {
    const { guestId, restaurantId } = useParams();
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState("");
    const [transactionId, setTransactionId] = useState(null); // Add this line
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [specialRequest, setSpecialRequest] = useState('');
    const [stripeAccountId, setStripeAccountId] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);

    const appearance = { theme: 'stripe' };
    const options = { clientSecret, appearance };
    const [currency, setCurrency] = useState('');
   
  

    useEffect(() => {
        const fetchStripeAccountId = async () => {
            if (!restaurantId) {
                console.error('Restaurant ID is not set');
                return;
            }

            const stripeRef = doc(firestore, `/restaurant/${restaurantId}/branch/stripe`);
            const stripeDoc = await getDoc(stripeRef);
            if (stripeDoc.exists()) {
                const stripeData = stripeDoc.data();
                setStripeAccountId(stripeData.stripeID);
            } else {
                console.error('Stripe account ID not found');
            }
        };

        fetchStripeAccountId();
    }, [restaurantId]);

    useEffect(() => {
        if (stripeAccountId) {
            setStripePromise(loadStripe('pk_live_51OOfyDKIP1dcMwGFHHsCdVGZ6WV4Xj43dAzedQsElkHYr0gT1ovmuGtXwsAaKQRnc5G8i3m2vaVDuMGzyDuAgjL300TZwbNptc', {
                stripeAccount: stripeAccountId,
            }));
        }
    }, [stripeAccountId]);




useEffect(() => {
    const fetchCurrency = async () => {
        const restaurantRef = doc(firestore, `restaurant/${restaurantId}`);
        const restaurantDoc = await getDoc(restaurantRef);
        if (restaurantDoc.exists()) {
            setCurrency(restaurantDoc.data().currency); // Assuming the field is named 'currency'
        } else {
            console.error('Restaurant data not found');
        }
    };

    fetchCurrency();
}, [restaurantId]);





    useEffect(() => {
        // Fetch Stripe Account ID and Payment Intent only once on component mount
        fetchStripeAccountId();

        // Set up real-time listener for cart items
        const cartRef = collection(firestore, `/restaurant/${restaurantId}/guests/${guestId}/cart`);
        const unsubscribe = onSnapshot(cartRef, snapshot => {
            let items = [];
            let total = 0;
        
            snapshot.forEach(doc => {
                const item = doc.data();
                const itemCount = item.itemCount || 0;
                const itemPrice = parseFloat(item.itemPrice) || 0;
                const totalItemPrice = parseFloat((itemPrice * itemCount).toFixed(2)); // Corrected to ensure two decimal places
    
                // Fetching the itemTax for each item
                const itemTax = item.itemTax || 0; // Default to 0 if itemTax is not set
    
                // Adding the itemTax to the item object
                items.push({ ...item, id: doc.id, totalItemPrice, itemCount, itemTax });
                
                total += totalItemPrice;
            });
    
            setCartItems(items);
            setTotalPrice(total);
            
        });
        

        return () => {
            unsubscribe(); // Unsubscribe from the listener when the component unmounts
        };
    }, [guestId, restaurantId]);

    useEffect(() => {
        if (totalPrice > 0 && stripeAccountId) {
            createPaymentIntent();
        }
    }, [totalPrice, stripeAccountId]); // Dependencies array

    const createPaymentIntent = async () => {
        if (totalPrice <= 0 || !stripeAccountId || clientSecret) {
            console.log('Conditions for creating payment intent are not met');
            return;
        }
        try {
            const formattedTotalPrice = Math.round(parseFloat(totalPrice.toFixed(2)) * 100);
            console.log('Formatted Total Price (in cents):', formattedTotalPrice);
            const lowercaseCurrency = currency.toLowerCase();
    
            const payload = { 
                totalPrice: formattedTotalPrice, 
                stripeAccount: stripeAccountId, 
                currency: lowercaseCurrency 
            };
            console.log('Request Payload:', payload);
    
            const response = await fetch('https://us-central1-nowaitr-70bf2.cloudfunctions.net/api/create-payment-intent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error Response:', errorText);
                throw new Error(errorText);
            }
    
            const data = await response.json();
            setClientSecret(data.clientSecret);
            setTransactionId(data.transactionId);
        } catch (error) {
            console.error('Error creating payment intent:', error);
        }
    };


     const fetchStripeAccountId = async () => {
        if (!restaurantId) {
            console.error('Restaurant ID is not set');
            return;
        }

        const stripeRef = doc(firestore, `/restaurant/${restaurantId}/branch/stripe`);
        const stripeDoc = await getDoc(stripeRef);
        if (stripeDoc.exists()) {
            const stripeData = stripeDoc.data();
            setStripeAccountId(stripeData.stripeID);
        } else {
            console.error('Stripe account ID not found');
        }
    };

    

    const updateItemQuantity = async (itemId, increment) => {
        setCartItems((prevItems) => {
            // Find the index of the item to update
            const itemIndex = prevItems.findIndex(item => item.id === itemId);
    
            if (itemIndex >= 0) {
                let updatedItems = [...prevItems];
                updatedItems[itemIndex] = {
                    ...updatedItems[itemIndex],
                    itemCount: updatedItems[itemIndex].itemCount + increment
                };
    
                if (updatedItems[itemIndex].itemCount > 0) {
                    const itemRef = doc(firestore, `/restaurant/${restaurantId}/guests/${guestId}/cart/${itemId}`);
                    updateDoc(itemRef, { itemCount: updatedItems[itemIndex].itemCount })
                        .catch((error) => console.error('Error updating item quantity:', error));
                } else {
                    const itemRef = doc(firestore, `/restaurant/${restaurantId}/guests/${guestId}/cart/${itemId}`);
                    deleteDoc(itemRef)
                        .catch((error) => console.error('Error deleting item:', error));
                }
    
                return updatedItems;
            } else {
                return prevItems; // Return the previous items if the item to update is not found
            }
        });
    };

    const increaseQuantity = (itemId) => {
        updateItemQuantity(itemId, 1);
    };

    const decreaseQuantity = (itemId) => {
        updateItemQuantity(itemId, -1);
    };
    
    const handleDelete = async (itemId) => {
        await deleteDoc(doc(firestore, `/restaurant/${restaurantId}/guests/${guestId}/cart/${itemId}`));
        // The real-time listener will handle the removal of the item from the cartItems state
    };

const handleBackNavigation = () => {
        const { restaurantId, guestId } = this.props;
        this.props.navigate(`/${restaurantId}/${guestId}/menu`);
};

return (
    <div className="cart-container">
        {/* Back Navigation with Menu Text */}
        <div className="back-navigation">
            <ArrowBackIosRoundedIcon className="back-icon" onClick={() => navigate(`/${restaurantId}/${guestId}/menu`)} />
            <span className="menu-text" onClick={() => navigate(`/${restaurantId}/${guestId}/menu`)}>Menu</span>
        </div>

        <h2>Your Order</h2> {/* Updated heading */}

        {cartItems.map(item => (
    <div key={item.id} className="cart-item">
        {/* Quantity Controls */}
        <div className="quantity-controls">
            <AddIcon onClick={() => increaseQuantity(item.id)} className="plus-icon" />
            <span className="quantity-text" style={{ margin: '0 10px' }}>{item.itemCount}</span>
            <RemoveIcon onClick={() => decreaseQuantity(item.id)} className="minus-icon" />
        </div>

        {/* Item Name and Description */}
        <div className="item-header">
            <strong className="item-name" style={{ fontWeight: 'normal' }}>{item.itemName}</strong>
            <p className="item-description" style={{ fontStyle: 'italic' }}>{item.itemDescription}</p>
        </div>

        {/* Item Price and Delete Icon */}
        <div className="price-remove">
            <span className="item-price">{(parseFloat(item.itemPrice) * item.itemCount).toFixed(2)} {currency}</span>
            <ClearRoundedIcon onClick={() => handleDelete(item.id)} className="delete-icon" />
        </div>
    </div>
))}

       {/* Special Request Section */}
<div className="special-request">
    <TextField
        id="outlined-multiline-flexible"
        label="Special Request"
        multiline
        maxRows={4}
        value={specialRequest}
        onChange={(e) => setSpecialRequest(e.target.value)}
        variant="outlined"
        fullWidth
    />
</div>

        {/* Total Price Display */}
        <h3>Total: {totalPrice.toFixed(2)} {currency}</h3>

        {/* Payment Section */}
        {stripeAccountId && clientSecret && stripePromise && (
            <div className="payment-section">
                <h3>Payment Options</h3>
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm 
                        clientSecret={clientSecret} 
                        restaurantId={restaurantId} 
                        guestId={guestId} 
                        transactionId={transactionId}
                    />
                </Elements>
            </div>
        )}
    </div>
);


        }

        export default CartWithParams;