import React from 'react';
import { Button, Box, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png';
import Mailsent from '../Illustrations/mailsent.png';

const RegistrationSuccess = () => {
    const location = useLocation();
    const { email } = location.state || {}; // assuming email is passed in state
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" bgcolor="#FCFCFC">
            <img src={Logo} alt="Company Logo" style={{ width: '20%', height: 'auto', marginBottom: 20, marginTop: 0 }}/>
            <img src={Mailsent} alt="Mail Sent" style={{ width: '25%', height: 'auto', marginBottom: -20 }}/>
            <Typography variant="h4" gutterBottom color={theme.palette.text.primary}>
                Registration Successful!
            </Typography>
            <Typography variant="h6" color={theme.palette.text.secondary}>
                Please verify your email address
            </Typography>
            <Typography variant="body1" gutterBottom>We've sent a verification email to:</Typography>
            <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                {email}
            </Typography>
            <Button variant="contained" color="primary" href="/login" style={{ marginTop: 20 }}>
                Go to Login
            </Button>
            {/* Additional interactive elements or navigation options can be added here */}
        </Box>
    );
};

export default RegistrationSuccess;
