// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyBtEtHnEwZbN-47U2f6EYAnSVK_k4He_nA",
  authDomain: "nowaitr-70bf2.firebaseapp.com",
  projectId: "nowaitr-70bf2",
  storageBucket: "nowaitr-70bf2.appspot.com",
  messagingSenderId: "860793324436",
  appId: "1:860793324436:web:ee772101f8935cc5fa8581",
  measurementId: "G-QM4S0T1FF4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
export const auth = getAuth(app);
export const firestore = getFirestore(app); // Firestore initialization
export const storage = getStorage(app);

export default app;