import React, { useState, useEffect } from 'react';
import '../../Styles/CreateMenu.css'; // Ensure correct path
import { MdAddCircleOutline, MdDelete, MdKeyboardArrowRight, MdKeyboardArrowDown, MdCheckCircle, MdKeyboardArrowUp, MdClear  } from 'react-icons/md';
import { firestore } from '../../Firebase/firebaseConfig'; // Import the firestore instance
import { getAuth, } from 'firebase/auth';
import { doc, getDocs, collection, addDoc, deleteDoc, updateDoc, query, where, getDoc, setDoc} from 'firebase/firestore';
import { TextField, IconButton, Button, InputLabel, FormControl, Select, MenuItem, FormLabel,Radio,RadioGroup,FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import ReactFlagsSelect from 'react-flags-select';
import CURRENCY_CODES from '../../Country/currency';





const CreateMenu = () => {
 const [menu, setMenu] = useState([{ categoryName: '', orderNumber: 1, categoryTax: '', items: [{ itemName: '', itemDescription: '', itemPrice: '', itemTax: '' }], isOpen: false }]);
   const [selectedCurrency, setSelectedCurrency] = useState('USD'); // Default currency
   const [taxOption, setTaxOption] = useState('brutto');



  const [isLoading, setIsLoading] = useState(false);
// No Empty ItemNames or Item Prices
  const validateMenuBeforeSave = () => {
    let isValid = true;
    menu.forEach(category => {
      category.items.forEach(item => {
        if (!item.itemName.trim() || !item.itemPrice.trim()) {
          isValid = false; // Set to false if any item name or price is empty
        }
      });
    });
    return isValid;
  };

  

  // Ensure order number is valid (not less than 1)
const validateOrderNumber = (orderNumber) => {
  return Math.max(orderNumber, 1);
};

 // Handler for category tax change
 const handleCategoryTaxChange = (index, event) => {
  const newMenu = [...menu];
  newMenu[index].categoryTax = event.target.value;
  newMenu[index].items = newMenu[index].items.map(item => ({ ...item, itemTax: event.target.value }));

  setMenu(newMenu);
};

// Handle changes in item tax details
const handleItemTaxChange = (categoryIndex, itemIndex, event) => {
  const newMenu = [...menu];
  newMenu[categoryIndex].items[itemIndex].itemTax = event.target.value;
  setMenu(newMenu);
};

// Adjust the addCategory function to ensure a valid starting order number
const addCategory = () => {
  const newOrderNumber = validateOrderNumber(menu.length + 1);
  setMenu([...menu, { categoryName: '', orderNumber: newOrderNumber, items: [{ itemName: '', itemDescription: '', itemPrice: '' }], isOpen: false }]);
};



const increaseOrderNumber = async (index) => {
  if (index < menu.length - 1) {
    const newMenu = [...menu];
    // Swap order numbers
    [newMenu[index].orderNumber, newMenu[index + 1].orderNumber] = [newMenu[index + 1].orderNumber, newMenu[index].orderNumber];
    newMenu.sort((a, b) => a.orderNumber - b.orderNumber);
    setMenu(newMenu);

    // Update in Firebase
    const updateFirebase = async () => {
      const categoryRef1 = doc(firestore, `/restaurant/${restaurantId}/menu`, newMenu[index].id);
      const categoryRef2 = doc(firestore, `/restaurant/${restaurantId}/menu`, newMenu[index + 1].id);

      await updateDoc(categoryRef1, {
        orderNumber: newMenu[index].orderNumber
      });

      await updateDoc(categoryRef2, {
        orderNumber: newMenu[index + 1].orderNumber
      });
    };

    updateFirebase();
  }
};

const decreaseOrderNumber = async (index) => {
  if (index > 0) {
    const newMenu = [...menu];
    // Swap order numbers
    [newMenu[index].orderNumber, newMenu[index - 1].orderNumber] = [newMenu[index - 1].orderNumber, newMenu[index].orderNumber];
    newMenu.sort((a, b) => a.orderNumber - b.orderNumber);
    setMenu(newMenu);

    // Update in Firebase
    const updateFirebase = async () => {
      const categoryRef1 = doc(firestore, `/restaurant/${restaurantId}/menu`, newMenu[index].id);
      const categoryRef2 = doc(firestore, `/restaurant/${restaurantId}/menu`, newMenu[index - 1].id);

      await updateDoc(categoryRef1, {
        orderNumber: newMenu[index].orderNumber
      });

      await updateDoc(categoryRef2, {
        orderNumber: newMenu[index - 1].orderNumber
      });
    };

    updateFirebase();
  }
};

  // Handle changes in category name
  const handleCategoryChange = (index, event) => {
    const newMenu = [...menu];
    newMenu[index].categoryName = event.target.value;
    setMenu(newMenu);
  };

  //Deleting Category


  // Handle changes in item details
  const handleItemChange = (categoryIndex, itemIndex, event) => {
    const newMenu = [...menu];
    const item = newMenu[categoryIndex].items[itemIndex];
  
    if (event.target.name === 'itemPrice') {
      // Format price when updating
      item[event.target.name] = event.target.value;
    } else {
      item[event.target.name] = event.target.value;
    }
  
    setMenu(newMenu);
  };

 

  // Add a new item to a category
  const addItem = (categoryIndex) => {
    
    const newMenu = [...menu];
    newMenu[categoryIndex].items.push({ itemName: '', itemDescription: '', itemPrice: '' });
    setMenu(newMenu);
  };

  // Toggle the visibility of category items
  const toggleCategory = (index) => {
    const newMenu = [...menu];
    newMenu[index].isOpen = !newMenu[index].isOpen;
    setMenu(newMenu);
  };
  const [deletedItems, setDeletedItems] = useState([]);
  const [deletedCategories, setDeletedCategories] = useState([]);


  // Delete an item from a category
  const deleteItem = (categoryIndex, itemIndex) => {
    const newMenu = [...menu];
    newMenu[categoryIndex].items.splice(itemIndex, 1);
    setMenu(newMenu);
  };

  // Currency options for selection
  const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'AUD', 'CAD']; // Add more currencies as needed


  //Success Message after clicking on save 
  const [showPopup, setShowPopup] = useState(false);


  // Firebase Integration
  const [restaurantId, setRestaurantId] = useState(null);

 
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setRestaurantId(user.uid);
  
        try {
          // Fetch global settings (currency and tax)
          const restaurantSettingsRef = doc(firestore, `/restaurant/${user.uid}`);
          const restaurantSettingsSnap = await getDoc(restaurantSettingsRef);
  
          if (restaurantSettingsSnap.exists()) {
            const data = restaurantSettingsSnap.data();
            setSelectedCurrency(data.currency || 'USD'); // Set currency from Firestore or default to 'USD'
            setTaxOption(data.tax || 'brutto'); // Set tax option from Firestore or default to 'brutto' (with Tax)
          } else {
            // If no settings are found in Firestore, use default values
            setSelectedCurrency('USD');
            setTaxOption('brutto');
          }
  
          // Fetch categories from Firestore
          const menuRef = collection(firestore, `/restaurant/${user.uid}/menu`);
          const menuSnapshot = await getDocs(menuRef);
          let fetchedCategories = [];
  
          menuSnapshot.docs.forEach(doc => {
            fetchedCategories.push({
              id: doc.id,
              categoryName: doc.data().categoryName,
              orderNumber: doc.data().orderNumber,
              categoryTax: doc.data().categoryTax || '0', // Fetching categoryTax, default to '0' if undefined
              items: [],
              isOpen: false
            });
          });
  
          // Sort categories based on orderNumber
          fetchedCategories.sort((a, b) => a.orderNumber - b.orderNumber);
  
          // Fetch items for each sorted category
          for (const category of fetchedCategories) {
            const itemsRef = collection(firestore, `/restaurant/${user.uid}/menu/${category.id}/items`);
            const itemsSnapshot = await getDocs(itemsRef);
            category.items = itemsSnapshot.docs.map(itemDoc => ({
              id: itemDoc.id,
              itemName: itemDoc.data().name,
              itemDescription: itemDoc.data().description,
              itemPrice: itemDoc.data().price,
              itemTax: itemDoc.data().tax, // Fetching itemTax, default to '0' if undefined
            }));
          }
  
          setMenu(fetchedCategories);
        } catch (error) {
          console.error('Error fetching menu or settings:', error);
          alert(`Error fetching data: ${error.message}`);
        }
      } else {
        console.log('No user logged in');
      }
    });
  
    return () => unsubscribe(); // Cleanup subscription
  }, []);
  
  const saveMenuToFirestore = async () => {
    if (!validateMenuBeforeSave()) {
      alert("Please fill in all item names and prices.");
      return;
    }
  
    setIsLoading(true);
  
    if (!restaurantId) {
      console.log('No restaurant ID found');
      setIsLoading(false);
      return;
    }
  
    try {
      // Check and set global restaurant settings if not already set
      const restaurantSettingsRef = doc(firestore, `/restaurant/${restaurantId}`);
      const restaurantSettingsSnap = await getDoc(restaurantSettingsRef);
      if (!restaurantSettingsSnap.exists()) {
        await setDoc(restaurantSettingsRef, {
          tax: taxOption,
          currency: selectedCurrency
        });
      } else {
        await updateDoc(restaurantSettingsRef, {
          tax: taxOption,
          currency: selectedCurrency
        });
      }
  
      for (let category of menu) {
        // Skip categories marked for deletion
        if (deletedCategories.includes(category.id)) {
          continue;
        }
  
        let categoryRef;
        if (category.categoryName) {
          const categoryQuery = query(collection(firestore, `/restaurant/${restaurantId}/menu`), where("categoryName", "==", category.categoryName));
          const querySnapshot = await getDocs(categoryQuery);
  
          if (querySnapshot.empty) {
            // Category does not exist, create new
            categoryRef = await addDoc(collection(firestore, `/restaurant/${restaurantId}/menu`), {
              categoryName: category.categoryName,
              orderNumber: category.orderNumber,
              categoryTax: category.categoryTax // Saving categoryTax
            });
          } else {
            // Category exists, update
            const docRef = querySnapshot.docs[0].ref;
            categoryRef = doc(firestore, `/restaurant/${restaurantId}/menu`, docRef.id);
            await updateDoc(categoryRef, {
              categoryName: category.categoryName,
              orderNumber: category.orderNumber,
              categoryTax: category.categoryTax // Updating categoryTax
            });
          }
        }
  
        // Update or create each item in the category
        for (let item of category.items) {
          // Skip items marked for deletion
          if (deletedItems.some(deletedItem => deletedItem.itemId === item.id)) {
            continue;
          }
          for (let item of category.items) {
            // Format itemPrice before saving
            item.itemPrice = parseFloat(item.itemPrice).toFixed(2);
          }
          let itemRef;
          if (item.id) {
            // If item has an ID, it exists and needs to be updated
            itemRef = doc(firestore, `/restaurant/${restaurantId}/menu/${categoryRef.id}/items`, item.id);
            await updateDoc(itemRef, {
              name: item.itemName,
              description: item.itemDescription,
              price: item.itemPrice,
              tax: formatTaxValue(item.itemTax) // Saving itemTax
            });
          } else {
            // Item does not exist, create new
            itemRef = collection(firestore, `/restaurant/${restaurantId}/menu/${categoryRef.id}/items`);
            await addDoc(itemRef, {
              name: item.itemName,
              description: item.itemDescription,
              price: item.itemPrice,
              tax: formatTaxValue(item.itemTax) // Saving itemTax
            });
          }
        }
      }
  
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      console.error('Error saving menu:', error);
      setIsLoading(false);
    }
  };
  

// Function to format and validate tax value
const formatTaxValue = (value) => {
  let tax = parseFloat(value);
  if (isNaN(tax) || tax < 0 || tax > 100) {
    return '0'; // Default value if invalid
  }
  return tax.toFixed(2); // Format to two decimal places
};
  


  // Add a function to handle deletions
  const deleteItemFromFirestore = async (categoryIndex, itemIndex) => {
    const category = menu[categoryIndex];
    const item = category.items[itemIndex];

    if (item && item.id) {
        // Add to deleted items list for tracking
        setDeletedItems(prev => [...prev, { categoryId: category.categoryName, itemId: item.id }]);

        // Delete from Firestore
        const itemRef = doc(firestore, `/restaurant/${restaurantId}/menu/${category.id}/items`, item.id);
        await deleteDoc(itemRef);
    }

    // Delete from local state
    deleteItem(categoryIndex, itemIndex);
};

const deleteCategoryFromFirestore = async (categoryIndex) => {
  const category = menu[categoryIndex];
  if (category && category.id) {
      // Track the category for deletion
      setDeletedCategories(prev => [...prev, category.id]);

      // Delete the category and its items from Firestore
      for (let item of category.items) {
          if (item.id) {
              const itemRef = doc(firestore, `/restaurant/${restaurantId}/menu/${category.id}/items`, item.id);
              await deleteDoc(itemRef);
          }
      }

      // Delete the category itself
      const categoryRef = doc(firestore, `/restaurant/${restaurantId}/menu`, category.id);
      await deleteDoc(categoryRef);

      // Update the local state to reflect the deletion
      const newMenu = [...menu];
      newMenu.splice(categoryIndex, 1);

      // Recalculate the orderNumber for each remaining category
      newMenu.forEach((category, index) => {
          category.orderNumber = index + 1;
      });

      setMenu(newMenu);

      // Update the orderNumbers in Firebase
      newMenu.forEach(async (updatedCategory) => {
          if (updatedCategory.id) {
              const categoryRef = doc(firestore, `/restaurant/${restaurantId}/menu`, updatedCategory.id);
              await updateDoc(categoryRef, {
                  orderNumber: updatedCategory.orderNumber
              });
          }
      });
  }
};

// Saving Radio Changes
const handleRadioChange = (event) => { 
  setTaxOption(event.target.value);
};


return (
  <div className="menu-creator">
  
  <h2 style={{ fontFamily: 'Arial, sans-serif', color: '#333', textAlign: 'center', fontWeight: 'bold' }}>Create your Menu</h2>
  <Divider textAlign="center" style={{ paddingTop: '20px' }}>Info</Divider>
    <div className="currency-selector">
   <FormControl variant="outlined" className="currency-form-control">
    <InputLabel>Select Currency</InputLabel>
    <Select
      value={selectedCurrency}
      onChange={(e) => setSelectedCurrency(e.target.value)}
      label="Select Currency"
      size="small"
     style={{ width: '150px', marginBottom: '30px' }}
    > 
      {currencies.map(currency => (
        <MenuItem key={currency} value={currency}>{currency}</MenuItem>
      ))}
    </Select>
  </FormControl> 

  <FormControl style={{ marginTop: '-8px', marginRight: '-100px' }}>
    <FormLabel sx={{ fontSize: '15px' }}>Prices are displayed...</FormLabel>
    <RadioGroup  row value={taxOption} onChange={(e) => handleRadioChange(e)}> {/* Add onChange handler */}
      <FormControlLabel value="brutto" control={<Radio  size="small" required/>}   label="with Tax" />
      <FormControlLabel value="netto" control={<Radio size="small" required />} disabled={true}   label="without Tax" />
    </RadioGroup>
  </FormControl>
</div>
<Divider textAlign="center" style={{ paddingBottom: '30px' }}>Menu</Divider>

    {menu.map((category, categoryIndex) => (
      <div key={categoryIndex} className="category-section">
        <div className="category-title">
          <IconButton onClick={() => toggleCategory(categoryIndex)}>
            {category.isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
          </IconButton>
          <TextField
            className="category-name-input"
            label="Category Name"
            variant="outlined"
            value={category.categoryName}
            onChange={(e) => handleCategoryChange(categoryIndex, e)}
            required
            style={{ width: '70%', marginBottom: '10px' }}
          />
          <TextField
            className="category-tax-input"
            label="Category Tax (%)"
            variant="outlined"
            value={category.categoryTax}
            onChange={(e) => handleCategoryTaxChange(categoryIndex, e)}
            type="number"
            inputProps={{ step: '0.01', min: '0', max: '100' }}
            InputLabelProps={{ shrink: true }}
            style={{ width: '25%', marginLeft: '15%', marginBottom: '10px' }}
            required
          />
          <IconButton onClick={() => deleteCategoryFromFirestore(categoryIndex)}>
            <MdDelete />
          </IconButton>
           <div className="button-container">
          {categoryIndex > 0 && (
            <IconButton onClick={() => decreaseOrderNumber(categoryIndex)} className="reorder-arrow">
              <MdKeyboardArrowUp />
            </IconButton>
          )}
          {categoryIndex < menu.length - 1 && (
            <IconButton onClick={() => increaseOrderNumber(categoryIndex)} className="reorder-arrow">
              <MdKeyboardArrowDown />
            </IconButton>
          )}
          </div>
          
        </div>

        {category.isOpen && category.items.map((item, itemIndex) => (
          <div key={itemIndex} className="item-section"
          >
            <TextField
              label="Name"
              variant="outlined"
              name="itemName"
              value={item.itemName}
              onChange={(e) => handleItemChange(categoryIndex, itemIndex, e)}
              size="small"
              style={{ width: '25%' }}
              required
            />
            <TextField
              label="Description"
              variant="outlined"
              name="itemDescription"
              value={item.itemDescription}
              onChange={(e) => handleItemChange(categoryIndex, itemIndex, e)}
              size="small"
              style={{ width: '35%'}}
            />
            <TextField
             
              label="Price"
              variant="outlined"
              name="itemPrice"
              type="number"
              value={item.itemPrice}
              onChange={(e) => handleItemChange(categoryIndex, itemIndex, e)}
              size="small"
              style={{ width: '13%' }}
              required
            />
            <TextField
            
              label="Tax (%)"
              variant="outlined"
              name="itemTax"
              value={item.itemTax}
              onChange={(e) => handleItemTaxChange(categoryIndex, itemIndex, e)}
              type="number"
              inputProps={{ step: '0.01', min: '0', max: '100' }}
              size="small"
              InputLabelProps={{ shrink: true }}
              style={{ width: '12%' }}
              required
            />
            <IconButton onClick={() => deleteItemFromFirestore(categoryIndex, itemIndex)}>
              <MdClear />
            </IconButton>
          </div>
        ))}

        {category.isOpen && (
          <IconButton onClick={() => addItem(categoryIndex)} className="add-item">
            <MdAddCircleOutline />
          </IconButton>
        )}
      </div>
    ))}

    <Button variant="contained" color="primary" onClick={addCategory} className="add-category-btn">
      Add Category
    </Button>
    <div className="bottom-bar">
      <Button
        variant="contained"
        color="primary"
        onClick={saveMenuToFirestore}
        disabled={isLoading}
        className="save-button"
      >
        {isLoading ? 'Saving...' : 'Save'}
      </Button>
      {showPopup && (
        <>
          <div className="overlay"></div>
          <div className="popup">
            <div className="popup-header">
              <MdCheckCircle className="checkmark-icon" /> Menu Saved Successfully!
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);
      }

export default CreateMenu;

