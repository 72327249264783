/* import React, { useState } from 'react';
import { TextField, Button, Stack, Box, Typography } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore } from 'firebase/firestore'; // Import Firestore methods
import { useAuth } from '../Firebase/AuthContext'; // Import useAuth
import { auth } from '../Firebase/firebaseConfig'; // Import auth
import { signInWithEmailAndPassword, sendPasswordResetEmail, emailVerified } from 'firebase/auth';

import Logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth(); // Destructure login from useAuth

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(email, password); // Login the user
            // After login, check the payment status
            const user = auth.currentUser; // Get the current user
            const db = getFirestore(); // Get Firestore instance
            const paymentDocRef = doc(db, `restaurant/${user.uid}/branch/payment`);

            getDoc(paymentDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const paymentData = docSnap.data();
                    if (paymentData.paid === true) {
                        navigate('/admin/create');
                    } else {
                        // If 'paid' is false or not set
                        navigate('/register/subscribe');
                    }
                } else {
                    // If there is no 'payment' document for the user
                    navigate('/register/subscribe');
                }
            }).catch((error) => {
                console.error("Failed to fetch payment status:", error);
                navigate('/register/subscribe'); // Default navigation on error
            });

        } catch (error) {
            console.error('Login Error:', error);
            alert(error.message); // Display error message to the user
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            alert("Please enter your email address.");
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent!");
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert(error.message);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" bgcolor="#FCFCFC">
            <img src={Logo} alt="Company Logo" style={{ width: '350px', height: 'auto', marginBottom: 50 }}/>

            <Box sx={{ width: { xs: '90%', sm: 350 }, bgcolor: 'white', borderRadius: '12px', boxShadow: 3, p: 3, mt: 1 }}>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h4" textAlign="center" marginBottom={2}>Login</Typography>
                    <Stack spacing={2}>
                        <TextField
                            type="email"
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            fullWidth
                            required
                        />
                        <TextField
                            type="password"
                            label="Password"
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            fullWidth
                            required
                        />
                        <Button variant="contained" color="primary" type="submit" fullWidth>Login</Button>
                        <Button variant="text" color="primary" onClick={handleForgotPassword} fullWidth>
                            Forgot Password?
                        </Button>
                    </Stack>
                </form>
                <Typography variant="body2" textAlign="center" marginTop={2}>
                    Don't have an account? <Link to="/register">Register Here</Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default LoginForm;
*/

import React, { useState } from 'react';
import { TextField, Button, Stack, Box, Typography } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useAuth } from '../Firebase/AuthContext';
import { auth } from '../Firebase/firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail, emailVerified } from 'firebase/auth';

import Logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(email, password);
            const user = auth.currentUser;
            const db = getFirestore();
            const paymentDocRef = doc(db, `restaurant/${user.uid}/branch/payment`);

            getDoc(paymentDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const paymentData = docSnap.data();
                    if (paymentData.paid === true) {
                        navigate('/admin/create');
                    } else {
                        navigate(`/register/subscribe?uid=${user.uid}`);
                    }
                } else {
                    navigate(`/register/subscribe?uid=${user.uid}`);
                }
            }).catch((error) => {
                console.error("Failed to fetch payment status:", error);
                navigate(`/register/subscribe?uid=${user.uid}`);
            });

        } catch (error) {
            console.error('Login Error:', error);
            alert(error.message);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            alert("Please enter your email address.");
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent!");
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert(error.message);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" bgcolor="#FCFCFC">
            <img src={Logo} alt="Company Logo" style={{ width: '350px', height: 'auto', marginBottom: 50 }}/>

            <Box sx={{ width: { xs: '90%', sm: 350 }, bgcolor: 'white', borderRadius: '12px', boxShadow: 3, p: 3, mt: 1 }}>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h4" textAlign="center" marginBottom={2}>Login</Typography>
                    <Stack spacing={2}>
                        <TextField
                            type="email"
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            fullWidth
                            required
                        />
                        <TextField
                            type="password"
                            label="Password"
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            fullWidth
                            required
                        />
                        <Button variant="contained" color="primary" type="submit" fullWidth>Login</Button>
                        <Button variant="text" color="primary" onClick={handleForgotPassword} fullWidth>
                            Forgot Password?
                        </Button>
                    </Stack>
                </form>
                <Typography variant="body2" textAlign="center" marginTop={2}>
                    Don't have an account? <Link to="/register">Register Here</Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default LoginForm;