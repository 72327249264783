const COUNTRY_CODES = {
    'EG': 'Egypt', 'AL': 'Albania', 'AG': 'Antigua and Barbuda', 'AR': 'Argentina', 'AM': 'Armenia',
    'ET': 'Ethiopia', 'AU': 'Australia', 'BS': 'Bahamas', 'BH': 'Bahrain', 'BE': 'Belgium',
    'BO': 'Bolivia', 'BA': 'Bosnia and Herzegovina', 'BR': 'Brazil', 'BG': 'Bulgaria',
    'CL': 'Chile', 'CR': 'Costa Rica', 'CI': "Côte d'Ivoire", 'DK': 'Denmark', 'DE': 'Germany',
    'DO': 'Dominican Republic', 'EC': 'Ecuador', 'SV': 'El Salvador', 'EE': 'Estonia', 'FI': 'Finland',
    'FR': 'France', 'GM': 'Gambia', 'GH': 'Ghana', 'GR': 'Greece', 'GT': 'Guatemala',
    'GY': 'Guyana', 'HK': 'Hong Kong', 'IN': 'India', 'ID': 'Indonesia', 'IE': 'Ireland',
    'IS': 'Iceland', 'IL': 'Israel', 'IT': 'Italy', 'JM': 'Jamaica', 'JP': 'Japan',
    'JO': 'Jordan', 'KH': 'Cambodia', 'CA': 'Canada', 'QA': 'Qatar', 'KE': 'Kenya',
    'CO': 'Colombia', 'HR': 'Croatia', 'KW': 'Kuwait', 'LV': 'Latvia', 'LI': 'Liechtenstein',
    'LT': 'Lithuania', 'LU': 'Luxembourg', 'MG': 'Madagascar', 'MY': 'Malaysia', 'MT': 'Malta',
    'MA': 'Morocco', 'MU': 'Mauritius', 'MX': 'Mexico', 'MN': 'Mongolia', 'NA': 'Namibia',
    'NZ': 'New Zealand', 'NL': 'Netherlands', 'NG': 'Nigeria', 'MK': 'North Macedonia', 'NO': 'Norway',
    'OM': 'Oman', 'AT': 'Austria', 'PA': 'Panama', 'PY': 'Paraguay', 'PE': 'Peru',
    'PH': 'Philippines', 'PL': 'Poland', 'PT': 'Portugal', 'MD': 'Moldova', 'RW': 'Rwanda',
    'RO': 'Romania', 'SA': 'Saudi Arabia', 'SE': 'Sweden', 'CH': 'Switzerland', 'SN': 'Senegal',
    'RS': 'Serbia', 'SG': 'Singapore', 'SK': 'Slovakia', 'SI': 'Slovenia', 'MO': 'Macau',
    'ES': 'Spain', 'LK': 'Sri Lanka', 'LC': 'Saint Lucia', 'ZA': 'South Africa', 'KR': 'South Korea',
    'TW': 'Taiwan', 'TZ': 'Tanzania', 'TH': 'Thailand', 'TT': 'Trinidad and Tobago', 'CZ': 'Czech Republic',
    'TN': 'Tunisia', 'TR': 'Turkey', 'HU': 'Hungary', 'UY': 'Uruguay', 'UZ': 'Uzbekistan',
    'AE': 'United Arab Emirates', 'US': 'United States', 'GB': 'United Kingdom', 'VN': 'Vietnam', 'CY': 'Cyprus'
};

export default COUNTRY_CODES;
