import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';

export default function CheckoutForm({ clientSecret, restaurantId, guestId }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
    }

    setIsLoading(true);

    // First, submit the elements form
    const { error: submitError } = await elements.submit();

    if (submitError) {
        setMessage(submitError.message);
        setIsLoading(false);
        return;
    }

    // Construct the full return URL dynamically
    const returnUrl = `${window.location.origin}/${restaurantId}/${guestId}/payment/success`;

    // Then, confirm the payment after the elements form is submitted
    const { error: confirmError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
        clientSecret,
    });

    if (confirmError) {
        if (confirmError.type === "card_error" || confirmError.type === "validation_error") {
            setMessage(confirmError.message);
        } else {
            setMessage("An unexpected error occurred.");
        }
    } else {
        // Handle successful payment confirmation here
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {/* Wrapper for centering the button */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button 
          variant="contained"
          endIcon={<SendIcon />}
          disabled={isLoading || !stripe || !elements}
          type="submit"
          style={{ backgroundColor: '#635BFF', color: 'white' }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Pay now"}
        </Button>
      </div>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
