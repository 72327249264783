import React, { Component, useEffect } from 'react';
import { firestore } from '../Firebase/firebaseConfig'; // Import firestore
import { collection, getDocs, getFirestore, doc, deleteDoc, addDoc, setDoc, getDoc} from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';


function RestaurantMenuWrapper() {
    const { restaurantId, guestId } = useParams(); // Extracting both restaurantId and guestId from URL

    const navigate = useNavigate();

    useEffect(() => {
        if (!guestId) {
            navigate(`/${restaurantId}/welcome`);
        }
    }, [guestId, navigate, restaurantId]);

    if (!guestId) {
        return null;
    }

    return <RestaurantMenu restaurantId={restaurantId} guestId={guestId} />;
}


const NavigateToCart = () => {
    const { restaurantId, guestId } = useParams();
    const navigate = useNavigate();

    const navigateToCartPage = () => {
        // Navigate to the cart page
        navigate(`/${restaurantId}/${guestId}/cart`);
    };

    return (
        <Button
            onClick={navigateToCartPage}
            style={{
                backgroundColor: '#161616', // Hex color code for background
                color: 'white', // White text and icon
                padding: '7px 12px',
                fontSize: '16px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease'
            }}
           // endIcon={<ShoppingCartCheckoutRoundedIcon />} // Icon added
        >
            Order
        </Button>
    );
};



    class ItemCounter extends Component {
        state = { count: 0 }
    
        componentDidMount() {
            this.fetchItemCount();
        }
    
        fetchItemCount = async () => {
            const { guestId, itemId, restaurantId } = this.props;
            console.log(`Fetching item count - Guest ID: ${guestId}, Item ID: ${itemId}, Restaurant ID: ${restaurantId}`);
        
            if (guestId && itemId && restaurantId) {
                const cartRef = doc(firestore, `restaurant/${restaurantId}/guests/${guestId}/cart/${itemId}`);
        
                try {
                    const cartSnap = await getDoc(cartRef);
                    if (cartSnap.exists()) {
                        const data = cartSnap.data();
                        console.log(`Fetched data for Item ID ${itemId}: `, data);
                        this.setState({ count: data.itemCount || 0 });
                    } else {
                        console.log(`Item ID ${itemId} not found in cart.`);
                        this.setState({ count: 0 });
                    }
                } catch (error) {
                    console.error(`Error fetching item count for Item ID ${itemId}: `, error);
                }
            } else {
                console.log("Either Guest ID, Item ID, or Restaurant ID is missing.");
            }
        }
    
        increment = () => { 
            this.setState({ count: this.state.count + 1 }, () => {
                this.props.onUpdate(this.props.itemPrice, this.state.count);
                this.props.onAddToCart({
                    id: this.props.itemId,
                    itemName: this.props.itemName,
                    itemDescription: this.props.itemDescription,
                    itemPrice: this.props.itemPrice,
                    itemCount: this.state.count,
                    itemTax: this.props.tax// Include itemCount in the object
                });
            });
        }
    
        decrement = async () => {
            if (this.state.count > 0) {
                const newCount = this.state.count - 1;
                this.setState({ count: newCount }, () => {
                    this.props.onUpdate(this.props.itemPrice, newCount);
                    if (newCount === 0) {
                        // Call the onRemoveFromCart method when the count is zero
                        this.props.onRemoveFromCart(this.props.itemId);
                    } else {
                        // Update the cart as usual
                        this.props.onAddToCart({
                            id: this.props.itemId,
                            itemName: this.props.itemName,
                            itemDescription: this.props.itemDescription,
                            itemPrice: this.props.itemPrice,
                            itemCount: newCount,// Include itemCount in the object
                            itemTax: this.props.tax 
                        });
                    }
                });
            }
        }


   
        render() {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={this.increment} size="small" style={{ color: 'black' }}>
                        <AddIcon />
                    </IconButton>
                    <span style={{ margin: '0 5px' }}>
                        {this.state.count}
                    </span>
                    <IconButton onClick={this.decrement} size="small" style={{ color: 'black' }} disabled={this.state.count === 0}>
                        <RemoveIcon />
                    </IconButton>
                </div>
            );
        }
    }

    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'EUR': return '€';
            case 'USD': return '$';
            // Add more currencies as needed
            default: return currency; // Fallback to the currency code itself
        }
    }

class Item extends Component {
    render() {
        const { guestId, header, description, price, tax, currency } = this.props; // Destructure props for easier access
        const currencySymbol = getCurrencySymbol(currency);
        console.log(`Item Rendered - Name: ${header}, Tax: ${tax}`); // Log the item name and tax

        return (
            <div style={{ borderBottom: '1px solid grey', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h3 style={{ fontWeight: 400 }}>{header}</h3> {/* Thinner font weight */}
                    <p style={{ fontStyle: 'italic' }}>{description}</p> {/* Cursive font style */}
                </div>
                <div style={{ textAlign: 'right' }}>
                    <p>{price}{currencySymbol}</p>
                    <ItemCounter
                        itemId={this.props.id}
                        guestId={guestId}
                        restaurantId={this.props.restaurantId}
                        itemPrice={price}
                        itemName={header}
                        itemDescription={description}
                        onUpdate={this.props.onUpdate}
                        onAddToCart={this.props.onAddToCart}
                        onRemoveFromCart={this.props.onRemoveFromCart}
                        tax={tax} // Passing itemTax to ItemCounter
                    />
                </div>
            </div>
        );
    }
}


class RestaurantMenu extends Component {
    state = { 
        categories: [],
        totalPrice: 0,
        guestId: null, // Add guestId to the state
        cartItems: {},
        restaurantId: this.props.restaurantId
    }

    componentDidMount() {
        this.fetchMenu();
        this.fetchCurrency();
        // Set the guestId from props to the state
        if (this.props.guestId) {
            this.setState({ guestId: this.props.guestId });
        }
    }

    
fetchCurrency = async () => {
    const firestore = getFirestore();
    const restaurantId = this.props.restaurantId;
    const restaurantRef = doc(firestore, `restaurant/${restaurantId}`);
    const restaurantSnap = await getDoc(restaurantRef);
    
    if (restaurantSnap.exists()) {
        const data = restaurantSnap.data();
        this.setState({ currency: data.currency || '' }); // Set the fetched currency to the state
    } else {
        console.log("Restaurant data not found");
    }
}


    addToCart = async (item) => {
        const { guestId, restaurantId } = this.state; // Use state values
        if (guestId && restaurantId) {
            const cartRef = doc(firestore, `restaurant/${restaurantId}/guests/${this.state.guestId}/cart/${item.id}`);
            const newItem = {
                itemName: item.itemName,
                itemDescription: item.itemDescription,
                itemPrice: item.itemPrice,
                itemCount: item.itemCount,
                itemTax: item.itemTax || 0 // Set default value if itemTax is undefined
            };
    
            console.log('Adding to cart:', newItem); // Debugging log right before setDoc
    
            await setDoc(cartRef, newItem); // Using setDoc to set the document with a specific ID
        
    
            // Update the state to reflect the addition to the cart
            this.setState(prevState => ({
                ...prevState,
                cartItems: {
                    ...prevState.cartItems,
                    [item.itemName]: item.id // Using the menu item's ID
                }
            }));
    
            return item.id; // Return the item ID
        }
    }
    

    removeFromCart = async (cartItemId) => {
        if (this.state.guestId && cartItemId) {
            const itemRef = doc(firestore, `/guests/${this.state.guestId}/cart/${cartItemId}`);
            await deleteDoc(itemRef);
        }
    }

    fetchMenu = async () => {
        const firestore = getFirestore();
        const restaurantId = this.props.restaurantId;
        const menuRef = collection(firestore, `/restaurant/${restaurantId}/menu`);
        const menuSnapshot = await getDocs(menuRef);
        let categories = [];
    
        for (const doc of menuSnapshot.docs) {
            const category = {
                id: doc.id,
                categoryName: doc.data().categoryName,
                orderNumber: doc.data().orderNumber, // Fetching orderNumber
                items: []
            };
            categories.push(category);
        }
    
        // Sort categories based on orderNumber
        categories.sort((a, b) => a.orderNumber - b.orderNumber);
    
        for (const category of categories) {
            const itemsRef = collection(firestore, `/restaurant/${restaurantId}/menu/${category.id}/items`);
            const itemsSnapshot = await getDocs(itemsRef);
            category.items = itemsSnapshot.docs.map(itemDoc => {
                const itemData = itemDoc.data();
                console.log(`Item: ${itemData.name}, Tax: ${itemData.tax || 'No tax info'}`); // Log itemTax for each item
    
                return {
                    id: itemDoc.id,
                    itemName: itemData.name,
                    itemDescription: itemData.description,
                    itemPrice: itemData.price,
                    itemTax: itemData.tax || 0 // Fetch itemTax, default to 0 if not present
                };
            });
        }
    
        this.setState({ categories });
    }

    // Function to scroll to specific section
    scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const offsetTop = section.offsetTop;
            window.scrollTo({
                top: offsetTop - 70, // Adjust for the fixed header's height
                behavior: 'smooth'
            });
        }
    }

    // Function to update total price when item count changes
    updateTotalPrice = (itemPrice, itemCount) => {
        this.setState(prevState => {
            const existingPrice = prevState.totalPrice;
            const newPrice = existingPrice + (itemPrice * itemCount);
            return { totalPrice: newPrice };
        });
    }

  
    


   
    render() {
        return (
            <div>
                {/* Navigation Bar with Pill-Shaped Items */}
                <header style={{ 
    position: 'fixed', 
    top: 0, 
    width: '100%', 
    backgroundColor: '#eceff1', 
    padding: '10px 0', 
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
    zIndex: 1000,
    overflowX: 'auto', // Enable horizontal scrolling
    overflowY: 'hidden', // Prevent vertical scrolling
}}>
    <nav style={{ whiteSpace: 'nowrap', textAlign: 'center' }}> {/* Ensure items are in a single line for horizontal scrolling */}
        <ul style={{ 
            listStyleType: 'none', 
            display: 'flex', 
            padding: '0px', 
            margin: '0 auto', 
            gap: '10px',
        }}>
            {this.state.categories.map((category, index) => (
                <li key={index} onClick={() => this.scrollToSection(`section${index + 1}`)} style={{ 
                    backgroundColor: '#161616', 
                    borderRadius: '20px', 
                    color: 'white', 
                    padding: '5px 15px',
                    display: 'inline-block', // Make sure items display inline for horizontal scrolling
                }}>
                    {category.categoryName}
                </li>
            ))}
        </ul>
    </nav>
</header>

                {/* Content Sections */}
                <div style={{ paddingTop: '70px', paddingBottom: '80px' }}>
                    {this.state.categories.map((category, index) => (
                        <section key={index} id={`section${index + 1}`} style={{ padding: '20px' }}>
                            <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>{category.categoryName}</h2>
                            {category.items.map(item => (
                                <Item
                                    key={item.id}
                                    id={item.id}
                                    header={item.itemName}
                                    description={item.itemDescription}
                                    price={item.itemPrice}
                                    currency={this.state.currency}
                                    tax={item.itemTax}
                                    onUpdate={this.updateTotalPrice}
                                    onAddToCart={this.addToCart}
                                    onRemoveFromCart={this.removeFromCart}
                                    cartItems={this.state.cartItems} // Passing down cartItems
                                    guestId={this.state.guestId}
                                    restaurantId={this.state.restaurantId}

                                    
                                />
                            ))}
                        </section>
                    ))}
                </div>

                {/* Fixed Footer for Ordering */}
                <div style={{ 
                    position: 'fixed', 
                    bottom: 0, 
                    left: 0, 
                    width: '100%', 
                    backgroundColor: '#fff', 
                    textAlign: 'center', 
                    padding: '10px 0',
                    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.2)'
                }}>
                   
                    <NavigateToCart guestId={this.state.guestId} restaurantId={this.state.restaurantId} />
                    
                </div>
            </div>
        );
    }
}

export default RestaurantMenuWrapper;

