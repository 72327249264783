import { jsPDF } from "jspdf";
import logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png'; // Ensure this path is correct

class ReceiptCreation {
  static generateBill(restaurantName, streetName, streetNumber, postalCode, city, country, taxID, tableNumber, orderId, transactionId, items, timestamp, currency, taxRate,tax) {
    const pdfDoc = new jsPDF();
    pdfDoc.setFont("Helvetica", "");

    // Restaurant Name, Tax ID, and Address - Centered with reduced gap
    pdfDoc.setFontSize(16);
    pdfDoc.text(restaurantName, 105, 20, { align: 'center' });
    pdfDoc.setFontSize(12);
    pdfDoc.text(`Tax ID: ${taxID}`, 105, 30, { align: 'center' });
    pdfDoc.text(`${streetName} ${streetNumber}`, 105, 36, { align: 'center' });
    pdfDoc.text(`${postalCode} ${city}`, 105, 42, { align: 'center' });
    pdfDoc.text(country, 105, 48, { align: 'center' });

    // Header
    pdfDoc.setFontSize(18);
    pdfDoc.text("Receipt", 105, 70, { align: 'center' });

    // Order Information
    pdfDoc.setFontSize(10);
    pdfDoc.text(`Table Number: ${tableNumber}`, 20, 90);
    pdfDoc.setFont("Helvetica", "italic");
    pdfDoc.text(`Date & Time: ${timestamp}`, 20, 95);
    pdfDoc.setFont("Helvetica", "italic");
    pdfDoc.text(`Order ID: ${orderId}`, 20, 100);
    pdfDoc.setFont("Helvetica", "italic");
    pdfDoc.text(`Transactionnumber: ${transactionId}`, 20, 105);
    pdfDoc.setFont("Helvetica", "italic");


  // Calculate the tax groups based on individual item tax rates
  const taxGroups = {};
  items.forEach(item => {
    let taxAmount;
    let itemTotal;
    if (tax === "brutto") {
      // For 'brutto', calculate tax amount and item total with tax included
      taxAmount = parseFloat(item.price) * parseFloat(item.tax) / (100 + parseFloat(item.tax));
      itemTotal = parseFloat(item.count) * parseFloat(item.price);
    } else { // "netto"
      // For 'netto', just calculate the tax amount, item total does not include tax
      taxAmount = parseFloat(item.price) * parseFloat(item.tax) / 100;
      itemTotal = parseFloat(item.count) * parseFloat(item.price);
    }
    item.taxAmount = parseFloat(taxAmount.toFixed(2));
    item.total = parseFloat(itemTotal.toFixed(2)); // Total for item (without tax for 'netto')
    // Grouping the tax amounts for displaying later
    if (taxGroups[item.tax]) {
      taxGroups[item.tax] += item.taxAmount * item.count;
    } else {
      taxGroups[item.tax] = item.taxAmount * item.count;
    }
  });

  
    // Items Table Header
    const headers = ["Count", "Price", "Name", "Total Price"];
    const columnPositions = [20, 60, 100, 140];
    let yPos = 118;

    headers.forEach((header, index) => {
      pdfDoc.text(header, columnPositions[index], yPos);
    });

    pdfDoc.setDrawColor(0);
    pdfDoc.line(20, yPos + 2, 190, yPos + 2);
    yPos += 10;

    // Items
    items.forEach(item => {
      pdfDoc.text(`${item.count}`, columnPositions[0], yPos);
      pdfDoc.text(`${item.price} ${currency}`, columnPositions[1], yPos);
      pdfDoc.text(item.name, columnPositions[2], yPos);
      pdfDoc.text(`${item.total} ${currency}`, columnPositions[3], yPos);
      yPos += 10;
    });

    pdfDoc.line(20, yPos, 190, yPos);

   // Calculate Subtotal and Total Tax
let subtotal = items.reduce((acc, item) => acc + item.total, 0); // Subtotal is sum of item totals
let totalTax = Object.values(taxGroups).reduce((acc, tax) => acc + tax, 0);

let total;
if (tax === "brutto") {
  total = subtotal; // In 'brutto', subtotal already includes tax
} else { // "netto"
  total = subtotal + totalTax; // For 'netto', add tax to subtotal for total
}

   // Displaying each tax line
   let taxLineYPos = yPos;
   Object.keys(taxGroups).forEach(taxRate => {
     const taxAmount = taxGroups[taxRate];
     taxLineYPos += 5;
     pdfDoc.text(`Tax (${parseFloat(taxRate).toFixed(0)}%): ${taxAmount.toFixed(2)} ${currency}`, 140, taxLineYPos);
   });


  // Display Subtotal, Total Tax, and Total
  pdfDoc.setFontSize(14);
  pdfDoc.setFont("Helvetica", "bold");
  pdfDoc.text(`Total: ${total.toFixed(2)} ${currency}`, 140, taxLineYPos + 20);
  pdfDoc.setFontSize(12);
  pdfDoc.setFont("Helvetica", "italic");
  pdfDoc.text(`Subtotal: ${subtotal.toFixed(2)} ${currency}`, 140, taxLineYPos + 10);

  yPos = taxLineYPos + 30;
    // Thank You Message
       // Thank You Message
       
       pdfDoc.setFontSize(14);
       pdfDoc.text("Thank you for dining with us!", 105, yPos, { align: 'center' });
       pdfDoc.setFontSize(12);
       pdfDoc.text(`${restaurantName} - Team`, 105, yPos + 10, { align: 'center' });
   
       // Service Provided By - Logo (Clickable) and Link
       // Ensure these elements are at the bottom of the page
       const bottomYPos = pdfDoc.internal.pageSize.height - 30; // Adjust position near the bottom
       pdfDoc.setFont("Helvetica", "italic");
       pdfDoc.text("Service provided by", 105, bottomYPos, { align: 'center' });
   
       // Adding the logo as a clickable link
       const logoWidth = 45;
       const logoHeight = 10;
       const logoX = (pdfDoc.internal.pageSize.width - logoWidth) / 2;
       pdfDoc.addImage(logo, 'PNG', logoX, bottomYPos + 5, logoWidth, logoHeight);
       pdfDoc.link(logoX, bottomYPos + 5, logoWidth, logoHeight, { url: 'https://www.nowaity.com' });
   
       // More about us link
       pdfDoc.text("More about us: www.nowaity.com", 105, bottomYPos + 20, { align: 'center' }); // Adjusted to be at the very bottom
   
       return pdfDoc.output("blob");
     }
   }
   
   export default ReceiptCreation;
   