import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc, } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Alert, CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import '../../Styles/profile.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
//import { storage } from 'firebase/app'; // Import the storage instance
import { storage } from '../../Firebase/firebaseConfig' ;
import UploadIcon from '@mui/icons-material/Upload';



const PersonalInformation = () => {
    // State for each field
    const [localName, setLocalName] = useState('');
    const [streetName, setStreetName] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [postalcode, setPostalcode] = useState('');
    const [city, setCity] = useState('');
    const [stateName, setStateName] = useState('');
    const [country, setCountry] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [position, setPosition] = useState('');
    const [title, setTitle] = useState('');
    const [logoFile, setLogoFile] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);

    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const userUID = user ? user.uid : null;

    const [isAddressExpanded, setIsAddressExpanded] = useState(false);
    const [isContactExpanded, setIsContactExpanded] = useState(false);

    const toggleAddress = () => setIsAddressExpanded(!isAddressExpanded);
    const toggleContact = () => setIsContactExpanded(!isContactExpanded);

    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            if (!userUID) return;

            try {
                const addressDocRef = doc(db, `/restaurant/${userUID}/branch/address`);
                const ownerDocRef = doc(db, `/restaurant/${userUID}/branch/owner`);
                const localDocRef = doc(db, `/restaurant/${userUID}/branch/local`);

                const addressDoc = await getDoc(addressDocRef);
                const ownerDoc = await getDoc(ownerDocRef);
                const localDoc = await getDoc(localDocRef);

                if (addressDoc.exists()) {
                    const addressData = addressDoc.data();
                    setStreetName(addressData.streetName || '');
                    setStreetNumber(addressData.streetNumber || '');
                    setPostalcode(addressData.postalcode || '');
                    setCity(addressData.city || '');
                    setStateName(addressData.stateName || '');
                    setCountry(addressData.country || '');
                }
                if (ownerDoc.exists()) {
                    const ownerData = ownerDoc.data();
                    setFirstName(ownerData.firstName || '');
                    setLastName(ownerData.lastName || '');
                    setEmail(ownerData.email || '');
                    setPhoneNumber(ownerData.phoneNumber || '');
                    setPosition(ownerData.position || '');
                    setTitle(ownerData.title || '');
                }
                if (localDoc.exists()) {
                    setLocalName(localDoc.data().localName || '');
                }
            } catch (error) {
                // Handle errors here
            }

            const logoRef = ref(storage, `/restaurant/${userUID}/logo/logo.jpg`); // Fixed file name
            getDownloadURL(logoRef)
                .then((url) => {
                    setLogoUrl(url);
                })
                .catch((error) => {
                    console.error("Error fetching logo URL", error);
                });
        };
    
        fetchData();
    }, [userUID]);

    const handleLogoUpload = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const storageRef = ref(storage, `/restaurant/${userUID}/logo/logo.jpg`); // Fixed file name
            await uploadBytes(storageRef, selectedFile);
    
            getDownloadURL(storageRef)
                .then((url) => {
                    setLogoUrl(url);
                })
                .catch((error) => {
                    console.error("Error fetching logo URL", error);
                });
        }
    };


   /* const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userUID) return;
    
        setIsLoading(true); // Start loading before the submission process
        setShowSuccessMessage(false); // Reset success message state
        setErrorMessage(''); 


        try {
            await updateDoc(doc(db, `/restaurant/${userUID}/branch/address`), {
                streetName, streetNumber, postalcode, city, stateName, country
            });
            await updateDoc(doc(db, `/restaurant/${userUID}/branch/owner`), {
                firstName, lastName, email, phoneNumber, position, title
            });
            await updateDoc(doc(db, `/restaurant/${userUID}/branch/local`), { localName });
    
            // Handle success
            setShowSuccessMessage(true); // Show success message
            setTimeout(() => setShowSuccessMessage(false), 3000); // Hide message after 3 seconds
            setErrorMessage("Failed to update profile. Please try again."); // Set custom error messag
        } catch (error) {
            setErrorMessage("Failed to update profile. Please try again."); // Set custom error messag
        } finally {
            setIsLoading(false); // Stop loading after the submission process
        }
    }; */

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userUID) return;
      
        setIsLoading(true); // Start loading before the submission process
        setShowSuccessMessage(false); // Reset success message state
        setErrorMessage('');
      
        try {
          // Upload the logo to Firebase Storage
          if (logoFile) {
            const storageRef = ref(storage, `/restaurant/${userUID}/logo/${logoFile.name}`);
            await uploadBytes(storageRef, logoFile);
          } 
      
          // Update other profile information as before
          await updateDoc(doc(db, `/restaurant/${userUID}/branch/address`), {
            streetName, streetNumber, postalcode, city, stateName, country
          });
          await updateDoc(doc(db, `/restaurant/${userUID}/branch/owner`), {
            firstName, lastName, email, phoneNumber, position, title
          });
          await updateDoc(doc(db, `/restaurant/${userUID}/branch/local`), { localName });
      
          // Handle success
          setShowSuccessMessage(true); // Show success message
          setTimeout(() => setShowSuccessMessage(false), 3000); // Hide message after 3 seconds
        } catch (error) {
          setErrorMessage("Failed to update profile. Please try again."); // Set custom error message
        } finally {
          setIsLoading(false); // Stop loading after the submission process
        }
      };

      return (
        <div className="personal-info">
            <h1>Profile</h1>
            <form onSubmit={handleSubmit}>
            <div className="logo-container">
    {logoUrl && <img src={logoUrl} alt="Logo" style={{ maxWidth: '40%', maxHeight: 'auto' }} />}
    <Button
        variant="contained"
        color="primary"
        component="label"
        startIcon={<UploadIcon />}
        style={{ marginTop: '50px', marginBottom: '20px' }} 
    >
        Upload Logo
        <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleLogoUpload}
        />
    </Button>
</div>
                <div className="centered-input">
                    <TextField 
                        label="Local Name" 
                        onChange={e => setLocalName(e.target.value)} 
                        value={localName} 
                        required 
                    />
                </div>
    
                <div className="section-header" onClick={toggleAddress}>
                    <IconButton>
                        {isAddressExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <h2 className="section-title">Address</h2>
                </div>
                {isAddressExpanded && (
                    <div className="section-content">
                        <div className="address-row">
                            <TextField 
                                label="Street Name" 
                                onChange={e => setStreetName(e.target.value)} 
                                value={streetName} 
                                required 
                            />
                            <TextField 
                                label="Street Number" 
                                onChange={e => setStreetNumber(e.target.value)} 
                                value={streetNumber} 
                                required 
                            />
                        </div>
                        <div className="address-row">
                            <TextField 
                                label="Postal Code" 
                                onChange={e => setPostalcode(e.target.value)} 
                                value={postalcode} 
                                required 
                            />
                            <TextField 
                                label="City" 
                                onChange={e => setCity(e.target.value)} 
                                value={city} 
                                required 
                            />
                            <TextField 
                                label="State" 
                                onChange={e => setStateName(e.target.value)} 
                                value={stateName} 
                            />
                        </div>
                        <TextField 
                            label="Country" 
                            onChange={e => setCountry(e.target.value)} 
                            value={country} 
                            required 
                        />
                    </div>
                )}
    
                <div className="section-header" onClick={toggleContact}>
                    <IconButton>
                        {isContactExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <h2 className="section-title">Contact</h2>
                </div>
                {isContactExpanded && (
                    <div className="section-content">
                        <div className="owner-row">
                            <FormControl>
                                <InputLabel id="title-label">Title</InputLabel>
                                <Select 
                                    labelId="title-label" 
                                    value={title} 
                                    label="Title" 
                                    onChange={e => setTitle(e.target.value)}
                                >
                                    <MenuItem value="Mr">Mr.</MenuItem>
                                    <MenuItem value="Mrs">Mrs.</MenuItem>
                                    <MenuItem value="Ms">Ms.</MenuItem>
                                    <MenuItem value="Dr">Dr.</MenuItem>
                                    <MenuItem value="Prof">Prof.</MenuItem>
                                    {/* Add more titles as needed */}
                                </Select>
                            </FormControl>
                            <TextField 
                                label="Position" 
                                onChange={e => setPosition(e.target.value)} 
                                value={position} 
                                required 
                            />
                        </div>
                        <div className="owner-row">
                            <TextField 
                                label="First Name" 
                                onChange={e => setFirstName(e.target.value)} 
                                value={firstName} 
                                required 
                            />
                            <TextField 
                                label="Last Name" 
                                onChange={e => setLastName(e.target.value)} 
                                value={lastName} 
                                required 
                            />
                        </div>
                        <div className="owner-row">
                            <TextField 
                                label="Email" 
                                onChange={e => setEmail(e.target.value)} 
                                value={email} 
                                required 
                            />
                            <PhoneInput
                                country={'de'}
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                inputStyle={{ width: '60%', height: 40 }}
                            />
                        </div>
                    </div>
                )}
    
                <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            disabled={isLoading}
        >
            {isLoading ? <CircularProgress size={24} /> : 'Save'}
        </Button>

        {showSuccessMessage && (
            <Alert severity="success" style={{ marginTop: '20px' }}>
                Profile updated successfully!
            </Alert>
            
        )}
       
            </form>
        </div>
    );
    
                }

export default PersonalInformation;