import React from 'react';
import QRCode from 'qrcode.react';
import { auth, storage } from '../../Firebase/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Button from '@mui/material/Button';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';

class QRCodeComponent extends React.Component {
    state = {
        size: 256, // Default QR code size
        logoUrl: '',
        includeLogo: false, // State to track if user wants to include a logo
        qrUrl: '', // Initially empty, will be updated after component mounts
    };

    componentDidMount() {
        this.setUserQRCode();
        this.updateQRCodeSize();
        window.addEventListener('resize', this.updateQRCodeSize);
        this.checkForExistingLogo();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateQRCodeSize);
    }

    setUserQRCode = () => {
        const user = auth.currentUser;
        if (user) {
            const restaurantID = user.uid;
            this.setState({
                qrUrl: `https://app.nowaity.com/${restaurantID}/welcome`
            });
        }
    }

    checkForExistingLogo = () => {
        const user = auth.currentUser;
        if (user) {
            const restaurantID = user.uid;
            this.fetchLogo(restaurantID);
        }
    }

    fetchLogo = async (restaurantID) => {
        const path = `/restaurant/${restaurantID}/qr/qrcode.jpg`;
        const storageRef = ref(storage, path);
        try {
            const url = await getDownloadURL(storageRef);
            this.setState({ logoUrl: url, includeLogo: true });
        } catch (err) {
            this.setState({ includeLogo: false }); // If no logo is found, reset the flag
            console.error('Error fetching logo:', err);
        }
    }

    handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const user = auth.currentUser;
        if (file && user) {
            const restaurantID = user.uid;
            const path = `/restaurant/${restaurantID}/qr/qrcode.jpg`;
            const storageRef = ref(storage, path);
            try {
                const snapshot = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);
                this.setState({ logoUrl: downloadURL, includeLogo: true });
            } catch (error) {
                console.error('Upload error:', error);
            }
        }
    }

    handleCheckboxChange = (e) => {
        this.setState({ includeLogo: e.target.checked });
    }

    updateQRCodeSize = () => {
        const newSize = Math.min(window.innerWidth * 0.6, 256);
        this.setState({ size: newSize });
    }

    downloadQRCode = () => {
        const canvas = document.querySelector('canvas');
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `qrcode_${this.state.size}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h2>Your QR Code</h2>
                <p style={{ marginBottom: '20px' }}>
                    URL for your Menu:
                    <a href={this.state.qrUrl} target="_blank" rel="noopener noreferrer">
                        {this.state.qrUrl || "Loading..."}
                    </a>
                </p>

                <div style={{ marginBottom: '20px' }}>
                    <label>
                        <input
                            type="checkbox"
                            checked={this.state.includeLogo}
                            onChange={this.handleCheckboxChange}
                        /> Do you want your logo inside of the QR Code?
                    </label>
                </div>

                {this.state.includeLogo && (
                    <div style={{ display: 'inline-block', marginBottom: '20px' }}>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<UploadRoundedIcon />}
                            style={{ backgroundColor: '#FB5633', color: 'white', maxWidth: '250px' }}
                        >
                            Upload Logo
                            <input type="file" hidden onChange={this.handleFileUpload} />
                        </Button>
                    </div>
                )}

                <div style={{ marginBottom: '20px' }}>
                    <QRCode
                        value={this.state.qrUrl || "Loading..."}
                        size={this.state.size}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        imageSettings={{
                            src: this.state.includeLogo ? this.state.logoUrl : undefined,
                            x: null,
                            y: null,
                            height: this.state.includeLogo ? 60 : undefined,
                            width: this.state.includeLogo ? 80 : undefined,
                            excavate: this.state.includeLogo,
                        }}
                    />
                </div>

                <Button
                    variant="contained"
                    startIcon={<DownloadRoundedIcon />}
                    style={{ backgroundColor: '#007bff', color: 'white', maxWidth: '250px', display: 'inline-block' }}
                    onClick={this.downloadQRCode}
                >
                    Download QR Code
                </Button>
            </div>
        );
    }
}

export default QRCodeComponent;