import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, writeBatch,query } from 'firebase/firestore';
import { firestore } from '../Firebase/firebaseConfig';
import logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png';
import pic from '../Illustrations/paysuccess.jpg';
import '../Styles/paysuccess.css';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import ReceiptCreation from './receiptcreation';



export default function PaymentSuccess() {
  const { restaurantId, guestId } = useParams();
  const [restaurantName, setRestaurantName] = useState('');
  const orderCreatedRef = useRef(false);
  const [orderId, setOrderId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [items, setItems] = useState([]); // State variables for new data
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [taxID, setTaxID] = useState('');
  const [currency, setCurrency] = useState('');
  const [tax, setTax] = useState('');
  const [tableNumber, setTableNumber] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [email, setEmail] = useState('');
  const [isBewirtungsbeleg, setIsBewirtungsbeleg] = useState(false);
  // ... Existing useEffect and other functions ...

  useEffect(() => {
    if (!orderId) return;
    // Fetch Restaurant Address Data
    const fetchRestaurantAddress = async () => {
      const addressRef = doc(firestore, `/restaurant/${restaurantId}/branch/address`);
      try {
        const docSnap = await getDoc(addressRef);
        if (docSnap.exists()) {
          setStreetName(docSnap.data().streetName);
          setStreetNumber(docSnap.data().streetNumber);
          setPostalCode(docSnap.data().postalcode);
          setCity(docSnap.data().city);
          setCountry(docSnap.data().country);
          setTaxID(docSnap.data().taxID);
        }
      } catch (error) {
        console.error("Error fetching address data: ", error);
      }
    };

    // Fetch Order Data
    const fetchOrderData = async () => {
      const orderRef = doc(firestore, `/restaurant/${restaurantId}/orders/${orderId}`);
      try {
        const docSnap = await getDoc(orderRef);
        if (docSnap.exists()) {
          setTimestamp(docSnap.data().timestamp);
          setTableNumber(docSnap.data().tableNumber);
        }
      } catch (error) {
        console.error("Error fetching order data: ", error);
      }
    };

    // Fetch Restaurant Info
    const fetchRestaurantInfo = async () => {
      const restaurantRef = doc(firestore, `/restaurant/${restaurantId}`);
      try {
        const docSnap = await getDoc(restaurantRef);
        if (docSnap.exists()) {
          setCurrency(docSnap.data().currency);
          setTax(docSnap.data().tax);
        }
      } catch (error) {
        console.error("Error fetching restaurant info: ", error);
      }
    };

       // Fetch Items from Firestore
       const fetchItems = async () => {
        const itemsRef = collection(firestore, `/restaurant/${restaurantId}/orders/${orderId}/items`);
        try {
          const querySnapshot = await getDocs(itemsRef);
          const fetchedItems = [];
          querySnapshot.forEach((doc) => {
            const item = doc.data();
            // Transform each item to the required format
            fetchedItems.push({
              name: item.itemName,
              description: item.itemDescription,
              count: item.itemCount,
              price: item.itemPrice,
              total: item.itemPrice * item.itemCount, // Assuming total is price * count
              tax: item.itemTax,
            });
          });
          setItems(fetchedItems);
        } catch (error) {
          console.error("Error fetching items: ", error);
        }
      };
   
    fetchItems();
    fetchRestaurantAddress();
    fetchOrderData();
    fetchRestaurantInfo();
  }, [restaurantId, orderId]);

useEffect(() => {
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  const paymentIntent = params.get('payment_intent');
  if (paymentIntent) {
    setTransactionId(paymentIntent);
    console.log("Transaction ID:", paymentIntent); // Log the transactionId
  }
}, []);


  useEffect(() => {
    const fetchRestaurantName = async () => {
      const docRef = doc(firestore, `restaurant/${restaurantId}/branch/local`);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRestaurantName(docSnap.data().localName);
        }
      } catch (error) {
        console.error("Error fetching restaurant data: ", error);
      }
    };

    fetchRestaurantName();

    if (!orderCreatedRef.current) {
      createOrderFromCart();
      orderCreatedRef.current = true;
    }
  }, [restaurantId, guestId]);




  const downloadReceipt = () => {
    const receiptBlob = ReceiptCreation.generateBill(
      restaurantName,
      streetName, //
      streetNumber, //
      postalCode, //
      city, // 
      country, //
      taxID,
      tableNumber,
      orderId,
      transactionId,
      items,
      timestamp,
      currency,
      tax,
      
    );

    const receiptURL = URL.createObjectURL(receiptBlob);
    const link = document.createElement('a');
    link.href = receiptURL;
    link.download = `Receipt-${orderId}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

 const createOrderFromCart = async () => {
    try {
      // Fetch guest document to get tableNumber
      const guestRef = doc(firestore, `restaurant/${restaurantId}/guests/${guestId}`);
      const guestSnap = await getDoc(guestRef);
  
      if (guestSnap.exists()) {
        const tableNumber = guestSnap.data().tableNumber;
        console.log("Fetched tableNumber:", tableNumber); // Logging the tableNumber
  
        // Fetch cart items
        const cartItemsRef = collection(firestore, `restaurant/${restaurantId}/guests/${guestId}/cart`);
        const querySnapshot = await getDocs(query(cartItemsRef));
  
        if (!querySnapshot.empty) {
          const batch = writeBatch(firestore);
  
          // Creating order data
          const orderData = {
            tableNumber: tableNumber,
            timestamp: new Date().toLocaleString(),
            status: "active"
          };
  
          // Adding order to firestore
          const orderRef = doc(collection(firestore, `restaurant/${restaurantId}/orders`));
          batch.set(orderRef, orderData);
  
          // Copying each cart item to the order with the same itemId
          querySnapshot.forEach((docSnapshot) => {
            const itemRef = doc(firestore, `restaurant/${restaurantId}/orders/${orderRef.id}/items/${docSnapshot.id}`);
            batch.set(itemRef, docSnapshot.data());
            batch.delete(doc(firestore, `restaurant/${restaurantId}/guests/${guestId}/cart`, docSnapshot.id));
          });
  
          // Committing the batch
          await batch.commit();
          console.log("Order created and cart cleared with ID:", orderRef.id);
  
          // Set orderId state and potentially generate receipt
          setOrderId(orderRef.id);
          console.log("Order ID:", orderRef.id);
        } else {
          console.error("Cart is empty");
        }
      } else {
        console.error("Guest document does not exist or missing tableNumber");
      }
    } catch (error) {
      console.error("Error creating order from cart: ", error);
    }
  };

  const sendReceiptToEmail = async () => {
    const receiptBlob = ReceiptCreation.generateBill(
      // Arguments passed to generateBill should be based on your application's state
      // This is a placeholder and should be adjusted according to your implementation
      restaurantName,
      streetName, //
      streetNumber, //
      postalCode, //
      city, // 
      country, //
      taxID,
      tableNumber,
      orderId,
      transactionId,
      items,
      timestamp,
      currency,
      tax,
      
    );

    const formData = new FormData();
    formData.append('file', receiptBlob, `Receipt-${new Date().toISOString()}.pdf`);
    formData.append('to', email);
    formData.append('subject', `Receipt from ${new Date().toLocaleDateString()}`);
    formData.append('text', `Hi! Im Anhang ist deine Rechnung, wir hoffen dir hat der Besuch gefallen so wie die Nutzung von Nowaity.\n\nViele Grüße\n\ndein Nowaity-Team`);

    fetch('https://us-central1-nowaitr-70bf2.cloudfunctions.net/api/send-receipt-email', {
      method: 'POST',
      body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
      alert('Receipt sent successfully!');
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('An error occurred while sending the receipt.');
    });
  };


  return (
    <div className="payment-success-container">
      <img src={pic} alt="Payment Success Illustration" className="pic" style={{ width: '60%', margin: '0 auto' }} />
      
      <h1 style={{ color: 'green' }}>Payment was successful!</h1>
      <p>Your Order is on the way!</p>
      
      
      <h2 style={{ textAlign: 'center', margin: '20px 0', fontSize: '24px' }}>Receipt</h2>
      <div style={{ textAlign: 'center', margin: '10px 0' }}>
        <FormControlLabel
          control={<Checkbox checked={isBewirtungsbeleg} onChange={(e) => setIsBewirtungsbeleg(e.target.checked)} />}
          label="Bewirtungsbeleg ?"
        />
      </div>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <p>Send Receipt to Mail</p>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Enter your email"
          endAdornment={
            <InputAdornment position="end">
              <Button onClick={sendReceiptToEmail} color="primary">
                <SendIcon />
              </Button>
            </InputAdornment>
          }
        />
      </div>

      <Button onClick={downloadReceipt} variant="contained" startIcon={<DownloadIcon />}>
        Download Receipt
      </Button>

      <div className="serviced-by">
        <h3>Serviced by</h3>
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </div>
  );
}