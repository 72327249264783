import React, { useEffect } from 'react';

const StripePricingTable = ({ clientReferenceId }) => {
  useEffect(() => {
    // Ensure the Stripe script is loaded
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Log the clientReferenceId to verify it is passed correctly
  console.log('Client Reference ID:', clientReferenceId);

  return (
    <stripe-pricing-table 
    pricing-table-id="prctbl_1PHdhIKIP1dcMwGFNLnJYNmK"
    publishable-key="pk_live_51OOfyDKIP1dcMwGFHHsCdVGZ6WV4Xj43dAzedQsElkHYr0gT1ovmuGtXwsAaKQRnc5G8i3m2vaVDuMGzyDuAgjL300TZwbNptc"
    client-reference-id={clientReferenceId}
    ></stripe-pricing-table>
  );
};

export default StripePricingTable;
