import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip, Divider } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from '../../Logo/NOWAITY/4x/LOGO V2Png File.png';
import { useAuth } from '../../Firebase/AuthContext'; // Import useAuth
import LogoutIcon from '@mui/icons-material/Logout';
import InsightsSharpIcon from '@mui/icons-material/InsightsSharp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';


const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { logout } = useAuth(); // Destructure logout from useAuth

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navigationOptions = [
    { name: 'Dashboard', path: '/admin/dashboard', icon: <InsightsSharpIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'Menu', path: '/admin/create', icon: <CreateIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'Profile', path: '/admin/personal', icon: <PersonIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'QR Code', path: '/admin/QRCode', icon: <QrCodeIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'Billing', path: '/admin/billing', icon: <ReceiptIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'Contact', path: '/admin/contact', icon: <ContactSupportIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    // Add other navigation options here
  ];
  

  const renderNavigationOption = (option, index) => (
    <Tooltip title={isCollapsed ? option.name : ''} placement="right" key={index}>
      <ListItem button component={Link} to={option.path}>
        <ListItemIcon>{option.icon} </ListItemIcon>
        {!isCollapsed && <ListItemText primary={option.name} style={{ color: 'white'}} />}
      </ListItem>
    </Tooltip>
  );

  // Placeholder to match the logo height
  const logoPlaceholder = <div style={{ height: '60px' }}></div>; // Adjust the height as per your logo's height

  return (
    <div>
      <IconButton onClick={toggleDrawer}>
      
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer} variant="persistent">
        <div style={{ 
          width: isCollapsed ? 55 : 190,
          backgroundColor: '#333333', 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          overflowX: 'hidden' }}>
          {/* Main content area */}
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              {isOpen && !isCollapsed ? <img src={Logo} alt="Logo" style={{ height: '30px', maxWidth: '100%', padding: '20px' }} /> : logoPlaceholder}
              <List>
                {navigationOptions.map(renderNavigationOption)}
              </List>
            </div>

            {/* Bottom part - Logout */}
            <div>
              <Divider />
              <ListItem button onClick={logout}>
                <ListItemIcon><LogoutIcon style={{ color: '#15A5C2', transform: 'scaleX(-1)' }} /></ListItemIcon>
                {!isCollapsed && <ListItemText primary="Logout" style={{ color: 'white'}}/>}
              </ListItem>
            </div>
          </div>

          {/* Chevron Icons on the right border, vertically centered */}
          <div style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}>
  <IconButton onClick={toggleCollapse} style={{ color: '#15A5C2' }}>
    {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
  </IconButton>
</div>
        </div>
      </Drawer>
    </div>
  );
};



export default SideNav;