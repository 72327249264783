import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, addDoc, getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import '../Firebase/firebaseConfig';
import logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png';
import '../Styles/customer_homepage.css';

const Homepage = () => {
  const [tableNumber, setTableNumber] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantLogo, setRestaurantLogo] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const restaurantId = params.restaurantId;

  useEffect(() => {
    const firestore = getFirestore();
    const storage = getStorage();

    const fetchRestaurantName = async () => {
      const docRef = doc(firestore, `/restaurant/${restaurantId}/branch/local`);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRestaurantName(docSnap.data().localName);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching restaurant name: ", error);
      }
    };

    const fetchRestaurantLogo = async () => {
      const storageRef = ref(storage, `/restaurant/${restaurantId}/logo/logo.jpg`);
      try {
        const url = await getDownloadURL(storageRef);
        setRestaurantLogo(url);
      } catch (error) {
        console.log('Error loading logo: ', error);
      }
    };

    fetchRestaurantName();
    fetchRestaurantLogo();
  }, [restaurantId]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setTableNumber(value);
    }
  };

  const handleSubmit = async () => {
    const firestore = getFirestore();
    const guestsRef = collection(firestore, `/restaurant/${restaurantId}/guests`);

    try {
      const docRef = await addDoc(guestsRef, {
        tableNumber: tableNumber,
      });
      navigate(`/${restaurantId}/${docRef.id}/menu`);
    } catch (error) {
      console.error('Error adding guest: ', error);
    }
  };

  return (
    <div className="container">
      <h1>Willkommen bei</h1>
      {restaurantLogo ? (
        <img src={restaurantLogo} alt="Restaurant Logo" className="restaurant-logo" />
      ) : restaurantName ? (
        <span className="restaurant-name">{restaurantName}</span>
      ) : (
        <span>Loading...</span>
      )}
      <p>An welchem Tisch finde ich dich?</p>
      <div className="input-button-container">
        <input
          type="text"
          value={tableNumber}
          onChange={handleInputChange}
          placeholder="z.B.12"
          className="input-field"
        />
        <button onClick={handleSubmit} className="submit-button">Weiter</button>
      </div>
      <div className="logo-container-powered">
        <p>Powered by</p>
        <img src={logo} alt="Logo" className="powered-logo" />
      </div>
    </div>
  );
      }

export default Homepage;
