import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Customer/homepage.js";
import LoginForm from "./Owner/login.js";
import Menu from './Customer/menu.js';
import RegisterForm from "./Owner/register.js";
// import Sidebar from "./Owner/sidebar/sidebar.js";
import CreateMenu from "./Owner/sidebar_options/createMenu.js";
import PersonalInformation from "./Owner/sidebar_options/personal.js";
import QRCode from "./Owner/sidebar_options/qrcode.js";
import Dashboard from "./Owner/sidebar_options/dashboard.js"
import SessionWrapper from "./Wrapper/session.js";
import Cart from "./Customer/cart.js";
import RestaurantMenuWrapper from "./Wrapper/menuwrapper.js";
import Sidebar from "./Owner/Sidebar/sidebar.js"
import Layout from "./Owner/Sidebar/layout.js";
import { AuthProvider } from './Firebase/AuthContext.js'; // Import AuthProvider
import ProtectedRoute from './Firebase/ProtectedRoute.js'; // Import ProtectedRoute
import RegistrationSuccess from "./Owner/registration_success.js";
import Billing from "./Owner/sidebar_options/billing.js";
import PaymentSuccess from "./Customer/paysuccess.js";
import Subscribe from "./Owner/subscribe_select.js";
import Contact from "./Owner/sidebar_options/contact.js";




function App() {
  return (
    <AuthProvider> {/* Wrap the application with AuthProvider */}
      <Router>
        <div className="App">
          <Routes>
            <Route path="/:restaurantId/welcome" element={<Homepage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/:restaurantId/:guestId/menu" element={<SessionWrapper />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/registration-success" element={<RegistrationSuccess />} />
            <Route path="/:restaurantId/:guestId/payment/success" element={<PaymentSuccess />} />
            <Route path="/register/subscribe" element={<Subscribe />} />
           
            {/* Protected Routes */}
            <Route path="/admin/dashboard" element={
              <ProtectedRoute>
                  <Layout><Dashboard /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/admin/create" element={
              <ProtectedRoute>
                <Layout><CreateMenu /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/admin/personal" element={
              <ProtectedRoute>
                <Layout><PersonalInformation /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/admin/QRCode" element={
              <ProtectedRoute>
                <Layout><QRCode /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/admin/billing" element={
              <ProtectedRoute>
                <Layout><Billing /></Layout>
              </ProtectedRoute>
            } />
             <Route path="/admin/contact" element={
              <ProtectedRoute>
                  <Layout><Contact /></Layout>
              </ProtectedRoute>
            } />


          <Route path="/:restaurantId/:guestId/cart" element={<Cart />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
